import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'
import clsx from 'clsx'

type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'normal' | 'flat'
  highlighted?: boolean
}

export const Button = ({
  onClick,
  children,
  size,
  variant,
  highlighted,
}: ButtonProps) => {
  const customerConfig = useCustomerConfig()

  return (
    <button
      type={'button'}
      onClick={onClick}
      className={clsx(
        'inline-flex gap-2 items-center rounded-lg border transform transition-transform duration-150',
        'hover:scale-100 active:scale-95',
        {
          'p-1 text-sm': size === 'small',
          'p-2': !size || size === 'medium',
          'p-3': size === 'large',
        },
        {
          [customerConfig.theme.bgColor]: variant === 'primary',
          'text-white hover:bg-opacity-100 dark:border-gray-700 dark:text-white dark:hover:bg-opacity-100':
            variant === 'primary',
          'dark:text-white hover:bg-opacity-20 border-0 hover:bg-black/20':
            variant === 'flat',
          'bg-gray-200 dark:bg-gray-800 bg-opacity-80 text-black dark:text-white hover:bg-opacity-90':
            !variant || variant === 'normal',
          'border-blue-500 border-2':
            highlighted && (!variant || variant === 'normal'),
          'border-gray-300 dark:border-gray-700':
            !highlighted && (!variant || variant === 'normal'),
        },
      )}
    >
      {children}
    </button>
  )
}
