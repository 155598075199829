import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'e2076f96-fa4d-4987-8213-b133597b30f6',
  header: 'Lab',
  subheader: '',
  version: 'Beta 7.0',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    isLight: false,
    bgColor: 'bg-blue-900',
    textColor: 'text-blue-900',
    bubbles: 'bg-green-600',
  },
  chatServer:
    (process.env.REACT_APP_CHAT_SERVER || 'https://ai.semanticlab.no') +
    '/api/v1/chatbots/lab',
}
export default config
