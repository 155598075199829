import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'
type ButtonGroupProps = {
  children: React.ReactNode
  header?: string
}
export const ButtonGroup = ({ children, header }: ButtonGroupProps) => {
  const customerConfig = useCustomerConfig()
  return (
    <div
      className={`flex flex-col gap-0 p-3 ${customerConfig.theme.isLight ? 'bg-gray-100' : 'bg-black/10'} dark:bg-gray-800 rounded-xl`}
    >
      {header && <h2 className="text-xs">{header}</h2>}
      {children}
    </div>
  )
}
