import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'
import clsx from 'clsx'

export const Sidebar = ({
  extraChildren,
  isOpen,
  availableTabs,
  activeTab,
  setActiveTab,
  darkMode,
}: {
  extraChildren?: React.ReactNode
  isOpen: boolean
  availableTabs: Tab[]
  activeTab: string
  setActiveTab: (tab: string) => void
  darkMode: boolean
}) => {
  const customerConfig = useCustomerConfig()

  const logoUrl = customerConfig.headerLogoUrl
    ? darkMode
      ? customerConfig.headerLogoUrl.dark
      : customerConfig.headerLogoUrl.light
    : null

  return (
    <header
      className={clsx(
        customerConfig.theme.isLight
          ? 'bg-white text-black dark:bg-gray-900 dark:text-white'
          : `${customerConfig.theme.bgColor} text-white`,
        'h-full flex flex-col items-center justify-between',
        isOpen ? 'w-60' : 'w-0 p-0 overflow-hidden',
        'lg:w-60 transition-all duration-500 ease-in-out',
      )}
    >
      <div className="flex flex-col items-center w-full">
        <div
          className={`flex flex-col items-center rounded-xl text-center gap-4`}
        >
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={customerConfig.header}
              className="max-h-[120px] p-4 w-full h-auto mt-2"
            />
          ) : (
            <h1 className="text-2xl font-bold p-4 mt-2">
              {customerConfig.header}
            </h1>
          )}
          {customerConfig.subheader && (
            <span className={`text-center p-3`}>
              {customerConfig.subheader}
            </span>
          )}
        </div>

        {/* Rest of the component remains the same */}
        <Tabs
          activeTab={activeTab}
          availableTabs={availableTabs}
          setSelectedTab={setActiveTab}
        />
      </div>
      <div className="w-full">{extraChildren}</div>
    </header>
  )
}

export type Tab = {
  id: string
  label: string
  icon?: React.ReactNode
}

type TabProps = {
  activeTab: string
  availableTabs: Tab[]
  setSelectedTab: (tab: string) => void
}

const Tabs = (props: TabProps) => {
  return (
    <nav className="flex flex-col mt-10 w-full">
      {props.availableTabs.map((tab) => (
        <TabButton
          key={tab.id}
          active={props.activeTab === tab.id}
          onClick={() => props.setSelectedTab(tab.id)}
        >
          {tab.icon}
          {tab.label}
        </TabButton>
      ))}
    </nav>
  )
}

const TabButton = ({
  children,
  active,
  onClick,
}: {
  children: React.ReactNode
  active: boolean
  onClick: () => void
}) => {
  return (
    <button
      onClick={onClick}
      className={`p-5 flex gap-2 w-full border-l-4 dark:text-white ${active ? 'border-blue-500 bg-gray-100 dark:bg-gray-800 dark:border-blue-300' : 'border-transparent'}`}
    >
      {children}
    </button>
  )
}
