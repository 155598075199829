import { AlternativeSystemPrompt } from '../chatApi'
import React, { useState } from 'react'
import { Button } from './Buttons'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
export const PromptDropdownSelect = ({
  options,
  onSelect,
  dropUp = false,
  children,
  selected,
  customButtons,
}: {
  options: AlternativeSystemPrompt[]
  onSelect: (item: AlternativeSystemPrompt) => void
  dropUp?: boolean
  children: React.ReactNode
  selected?: string
  customButtons?: { onClick: () => void; text: string }[]
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (item: AlternativeSystemPrompt) => {
    onSelect(item)
    setIsOpen(false)
  }

  return (
    <div className="relative w-full">
      <Button onClick={() => setIsOpen(!isOpen)} variant="flat">
        {children} <ArrowDropDownIcon className="self-end" />
      </Button>
      {isOpen && (
        <div
          className={`absolute w-full bg-white dark:bg-gray-800 border dark:border-gray-700 rounded shadow-[0_0_55px_rgba(0,0,0,0.75)] ${dropUp ? 'bottom-full mb-2' : 'mt-2'}`}
        >
          {options.map((option) => (
            <button
              key={option.name}
              className={`block w-full text-left px-4 py-2 text-black hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-white ${selected === option.name ? 'bg-gray-100 dark:bg-gray-600' : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option.name}
              {selected === option.name && (
                <CheckIcon className="float-right" />
              )}
            </button>
          ))}
          {customButtons && (
            <div className="border-t dark:border-gray-700">
              {customButtons.map((button) => (
                <button
                  key={button.text}
                  className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-white"
                  onClick={() => {
                    setIsOpen(false)
                    button.onClick()
                  }}
                >
                  {button.text}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
