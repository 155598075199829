import mattilsynet from './mattilsynet'
import snowchat from './snowchat'
import lab from './lab'
import rusinfo from './rusinfo'
import felleskatalogen from './felleskatalogen'

const configs = {
  mattilsynet,
  rusinfo,
  snowchat,
  lab,
  felleskatalogen,
}

export default configs
