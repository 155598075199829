import React from 'react'
import { useMsal } from '@azure/msal-react'
import UserIcon from '@mui/icons-material/AccountCircle'

export const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div
      className="flex items-center gap-2 p-2"
      tabIndex={0}
      role={'button'}
      onClick={handleLogin}
    >
      <UserIcon style={{ fontSize: 30 }} />
      {accounts && accounts.length ? (
        <span>{accounts[0].name}</span>
      ) : (
        <span>Logg inn</span>
      )}
    </div>
  )
}
