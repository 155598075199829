export type QuestionnaireResponse = {
  filledQuestionnaire: string // JSON string of filled answers
}

export type QuestionnaireSubmitResponse = {
  success: boolean
  message?: string
}

// Add these new functions
export const fillQuestionnaire = async (
  bearerToken: string,
  chatServer: string,
  text: string,
): Promise<QuestionnaireResponse> => {
  const response = await fetch(`${chatServer}/questionnaire/autofill`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({ text }),
  })
  if (!response.ok) {
    throw new Error('Failed to fill questionnaire')
  }
  return response.json()
}

export const submitQuestionnaire = async (
  bearerToken: string,
  chatServer: string,
  answers: Record<string, any>,
): Promise<QuestionnaireSubmitResponse> => {
  const response = await fetch(`${chatServer}/questionnaire/answers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({ answers }),
  })
  if (!response.ok) {
    throw new Error('Failed to submit questionnaire')
  }
  return response.json()
}

export const getQuestionnaireFields = async (
  bearerToken: string,
  chatServer: string,
): Promise<any[]> => {
  const response = await fetch(`${chatServer}/questionnaire/questions`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  })
  if (!response.ok) {
    throw new Error('Failed to get questionnaire fields')
  }
  const json = await response.json()
  return json.questions
}

export const createNewChatbotThread = async (
  bearerToken: string,
  chatServer: string,
) => {
  const response = await fetch(chatServer + '/threads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })
  return (await response.json()).id as string
}

export const postChatMsgToThreadAndStreamResponse = async function* (
  bearerToken: string,
  chatServer: string,
  threadId: string,
  messageText: string,
  systemPromptId: string | null, // If NULL, then custom.
  customSystemPrompt: string | null,
) {
  const newChatResponse = await fetch(
    chatServer + '/threads/' + threadId + '/messages',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
      body: JSON.stringify({
        message: messageText,
        systemPromptId: systemPromptId,
        customSystemPrompt:
          systemPromptId == null ? customSystemPrompt : undefined,
      }),
    },
  )

  const reader = newChatResponse.body?.getReader()
  let decoder = new TextDecoder()
  let buffer = '' // Accumulate chunks here

  let result = await reader?.read()
  while (result && !result?.done) {
    // Decode the current chunk and append it to the buffer
    buffer += decoder.decode(result.value, { stream: true })

    // Split the buffer by newline characters to get complete lines
    const lines = buffer.split('\n')

    // The last element might be an incomplete line, so we keep it in the buffer
    buffer = lines.pop() || ''

    for (const line of lines) {
      try {
        // Parse each complete line as JSON
        const trimmed = line.trim()
        if (trimmed.length === 0) continue
        const jsonObject = JSON.parse(trimmed)
        yield jsonObject
      } catch (e) {
        console.error('Failed to parse JSON:', line, e)
      }
    }

    // Read the next chunk
    result = await reader?.read()
  }

  // If there is any remaining text in the buffer after reading all chunks, process it
  if (buffer.trim()) {
    try {
      const jsonObject = JSON.parse(buffer.trim())
      yield jsonObject
    } catch (e) {
      console.error('Failed to parse JSON:', buffer, e)
    }
  }
}

export type AlternativeSystemPrompt = {
  name: string
  prompt?: string
}

export type Me = {
  systemPrompt: string | null
  isAdmin: boolean
  canUseCustomSystemPrompt: boolean
  alternativeSystemPrompts: AlternativeSystemPrompt[]
}
export const getMe = async (
  token: string,
  chatServer: string,
): Promise<Me | null> => {
  const response = await fetch(chatServer + '/me', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  if (response.ok) {
    return await response.json()
  } else {
    console.error('Failed to GET /me')
    return null
  }
}
