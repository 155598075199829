import useCustomerConfig from '../hooks/useCustomerConfig'
import React, { useEffect, useState } from 'react'
import { Chat } from './Chat'
import { Message } from '../types'
import FeedbackIcon from '@mui/icons-material/Feedback'
import { Button } from './Buttons'
import { SmileyIcon } from './GiveFeedback'
import { Dialog } from './Dialog'

type Feedback = {
  id: string
  conversation: Message[]
  grade: number
  comment: string
  reportedBy: string
}
type HistoricConversationViewerProps = {
  getToken: () => Promise<string>
}
export const FeedbackExplorer = (props: HistoricConversationViewerProps) => {
  const customerConfig = useCustomerConfig()
  const [feedbacks, setFeedbacks] = useState<any[]>([])
  const [feedback, setFeedback] = useState<Feedback | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const loadHistoricConversation = async (conversationId: string) => {
    const token = await props.getToken()
    const response = await fetch(
      customerConfig.chatServer + '/feedback/' + conversationId,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    const feedback = await response.json()
    setFeedback(feedback)
  }

  const fetchHistoricConversations = async () => {
    const token = await props.getToken()
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const response = await fetch(customerConfig.chatServer + '/feedback', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.ok) {
      const conversations = await response.json()
      setFeedbacks(conversations)
    }
  }

  useEffect(() => {
    if (feedbacks.length === 0) {
      fetchHistoricConversations()
    }
  }, [])

  return (
    <div>
      {feedbacks.length > 0 && (
        <div>
          <Button onClick={() => setDialogOpen(true)} variant="flat">
            <FeedbackIcon />
            Tilbakemeldinger
          </Button>
          <Dialog
            isOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
            header={'Tilbakemeldinger'}
          >
            <section className="flex gap-2">
              <div className="max-w-96 gap-1 flex flex-col border-2 border-gray-300 rounded-lg p-2 dark:bg-gray-800 dark:border-gray-700">
                {feedbacks.map((conv, index) => (
                  <div
                    role={'button'}
                    key={index}
                    onClick={() => loadHistoricConversation(conv.id)}
                    className={
                      'p-2 rounded-lg bg-gray-100 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600'
                    }
                  >
                    <div
                      className={
                        'text-gray-600 flex justify-between dark:text-gray-400'
                      }
                    >
                      <span>
                        {new Date(conv.addedAt).toLocaleString('nb-NO')}
                      </span>
                      <span>
                        {conv.reportedBy || ' '}{' '}
                        <SmileyIcon
                          grade={Math.max(1, Math.min(5, conv.grade))}
                        />
                      </span>
                    </div>
                    <p>{conv.comment}</p>
                  </div>
                ))}
              </div>
              <div className={'flex-1 min-w-96'}>
                <Chat
                  conversation={feedback?.conversation || []}
                  emptyConversationPlaceholder={<p>Velg en feedback</p>}
                />
              </div>
            </section>
          </Dialog>
        </div>
      )}
    </div>
  )
}
